<template>
  <div v-if="server" id="skeleton">
    <div class="flex flex-wrap gap-6 justify-center">
      <div v-for="_ in 9" class="flex flex-col gap-2 items-center">
        <div
          class="h-20 w-20 md:w-28 md:h-28 lg:h-32 lg:w-32 bg-gray-300 animate-pulse rounded-full mb-8 md:mb-12"
        ></div>
      </div>
    </div>
    <div
      class="mt-10 animate-pulse bg-gray-300 mx-auto w-60 rounded-full h-6"
    />
  </div>

  <article
    class="flex flex-col justify-center gap-8"
    v-else-if="!server && $categorias.length"
  >
    <div class="flex flex-wrap gap-6 justify-center">
      <a
        v-for="(categoriaIteracion, index) in $categorias"
        class="flex flex-col gap-2 md:gap-3 items-center"
        :key="index"
        :href="`/categoria/${categoriaIteracion.ruta}`"
      >
        <picture>
          <source
            :srcset="categoriaIteracion.urls_imagen.webp.s"
            type="image/webp"
          />
          <source
            :srcset="categoriaIteracion.urls_imagen.jpg.s"
            type="image/jpg"
          />
          <img
            :alt="categoriaIteracion.nombre"
            class="rounded-full h-20 w-20 md:w-28 md:h-28 lg:h-32 lg:w-32"
          />
        </picture>
        <span class="font-bold text-xs uppercase w-max md:text-sm">{{
          categoriaIteracion.nombre
        }}</span>
      </a>
    </div>
    <a
      @click="abrirMenu"
      class="place-self-center self-center text-center text-base font-semibold text-green-500 underline md:hover:text-green-700 focus:text-green-700 cursor-pointer"
    >
      Ver todas las categorías
    </a>
  </article>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "@vue/runtime-core";
  import { useStore } from "@nanostores/vue";
  import { abrirMenu, categoriasInicio } from "@stores/app";

  const $categorias = useStore(categoriasInicio);

  const server = ref(true);

  onMounted(() => {
    server.value = false;
  });
</script>
